import { GetServerSideProps } from "next";
import Head from "next/head";
import { IntlProvider } from "react-intl";
import Footer from "../components/footer";
import { TranslatedMessage } from "../components/i18n";
import {
  isLanguage,
  loadTranslation,
  pickLangFromHeaders,
  TranslationInfo,
} from "../components/language";
import { useEffect, useState } from "react";

type Props = {
  translation: TranslationInfo;
};

const VPN_HINT =
  "Недоступно из-за жалобы правообладателя в данном регионе. Возможно, у вас включен VPN.";

export default function DmcaPage({ translation }: Props) {
  return (
    <IntlProvider
      locale={translation.lang}
      defaultLocale="en"
      messages={translation.messages ?? undefined}
    >
      <Head>
        <title>
          {translation.messages["dmca.page.title"] ??
            "Taken down by DMCA request"}
        </title>
        <meta name="robots" content="noindex" />
      </Head>
      <DmcaPageContent />
    </IntlProvider>
  );
}

export function DmcaPageContent() {
  const [useVpnHint, setUseVpnHint] = useState(false);
  useEffect(() => {
    setUseVpnHint(navigator.language.includes("ru"));
  }, [navigator.language]);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          padding: "0 2rem",
          height: "calc(100vh - var(--nextui--navbarHeight))",
          textAlign: "center",
        }}
      >
        {useVpnHint ? VPN_HINT : <TranslatedMessage id="dmca.page.body" />}
      </div>
      <Footer />
    </>
  );
}

export const getServerSideProps: GetServerSideProps<Props> = async (
  context
) => {
  if (context.locale === "default") {
    const selectedLanguage = pickLangFromHeaders(context.req.headers);
    const query = context.query
      ? // @ts-ignore
        `?${new URLSearchParams(context.query).toString()}`
      : "";
    return {
      redirect: {
        destination: `/${selectedLanguage}/dmca${query}`,
        permanent: false,
      },
    };
  }
  context.res.statusCode = 451;
  context.res.setHeader(
    "Cache-Control",
    "public, s-maxage=86400, stale-while-revalidate=86400, stale-if-error=86400"
  );
  context.res.setHeader("X-Robots-Tag", "noindex");

  const translation = await loadTranslation({
    lang: isLanguage(context.locale) ? context.locale : "en",
  });

  const props: Props = {
    translation,
  };

  return {
    props,
  };
};
